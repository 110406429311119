<template>
    <div class="p-login">
        <div class="container-xl d-flex align-items-center justify-content-center">
            <div class="p-login__content d-flex flex-column align-items-center justify-content-center">
                <h1>Welcome to BYC's tool for creating personas</h1>
                <a class="btn btn-outline-secondary btn-google" @click="login"><img src="../assets/svg/icon-google.png"> Sign in with google</a>
            </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'Login',

    methods: {
        ...mapActions('user', {
            login: 'login',
        })
    }
}
</script>
